import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpResponse } from '@angular/common/http';
import { BimPerson } from './bim-person.model';
import {ThsBaseService } from  '@shared/components/thsBaseService';
import { HttpAddressService } from '@shared/session/http-address.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class BimPersonService extends ThsBaseService<BimPerson> {
    constructor(http: HttpClient, httpAddressService: HttpAddressService) {
        super(http, httpAddressService.BimServe + `/bim-people`);
    }


        /** GET: 分页、多条件查询记录列表 */
      queryNew (queryParams ?: any): Observable<HttpResponse<BimPerson[]>> {
          let url = this.resourceUrl + "/getAllBimTeamsNew"
          return this.http.get<BimPerson[]>(url, {params: queryParams ,  observe: 'response'})
          .pipe(map((res: HttpResponse<BimPerson[]>) => this.convertDateArrayFromServer(res)));
      }

      /** GET: 分页、多条件查询记录列表 */
      queryNewAnd (queryParams ?: any): Observable<HttpResponse<BimPerson[]>> {
        let url = this.resourceUrl + "/getAllBimTeamsNewAnd"
        return this.http.get<BimPerson[]>(url, {params: queryParams ,  observe: 'response'})
        .pipe(map((res: HttpResponse<BimPerson[]>) => this.convertDateArrayFromServer(res)));
    }



      getExportExcelCase(queryParams ?: any): Observable<HttpResponse<any>> {
        const url = `${this.resourceUrl}/getExportExcelCase`;
        return this.http.get<HttpResponse<any>>(url, { params:queryParams, observe: 'response' });
      }

      removeExportExcelCase(queryParams ?: any) {
        const url = `${this.resourceUrl}/removeExportExcelCase`;
        return this.http.get(url, { params: queryParams, observe: 'response' }).subscribe();
      }


      exportExcel(queryParams ?: any){
        const url = `${this.resourceUrl}/exportExcel`;
        return this.http.get(url, { params: queryParams, observe: 'response' }).subscribe();
      }


      downloadExportExcelFile(queryParams ?: any){
        const url = `${this.resourceUrl}/downloadExportExcelFile`;
       // return this.http.get(url, { params: {}, observe: 'response' }).subscribe();
            //需要token验证的打开下载链接的方式与不需要验证的windows.open是一样的
            this.http.get(url ,  {        
              params: queryParams ,   
              headers: new HttpHeaders({ 'Content-Type': 'application/vnd.ms-excel' }),
              observe: 'response',
              responseType: 'blob',
              withCredentials: true,
            }).subscribe(
              data => {
                let blob = new Blob([data.body]);
                let objectUrl = URL.createObjectURL(blob);
                let a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                a.setAttribute('download',  '个人参赛名单' + '.xlsx');
                a.click();
                URL.revokeObjectURL(objectUrl);
              },
              error => {
                this.handleError('下载失败,请稍后重试!');
                console.error('个人参赛名单下载失败 ->', error);
              },
            );


      }

      exportExcel_old_2(queryParams ?: any) {
        const url = `${this.resourceUrl}/exportExcel_old_2`;
    
        //需要token验证的打开下载链接的方式与不需要验证的windows.open是一样的
        this.http.get(url ,  {
          params: queryParams ,
          headers: new HttpHeaders({ 'Content-Type': 'application/vnd.ms-excel' }),
          observe: 'response',
          responseType: 'blob',
          withCredentials: true,
        }).subscribe(
          data => {
            let blob = new Blob([data.body]);
            let objectUrl = URL.createObjectURL(blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display:none');
            a.setAttribute('href', objectUrl);
            a.setAttribute('download',  '个人参赛名单' + '.xlsx');
            a.click();
            URL.revokeObjectURL(objectUrl);
          },
          error => {
            this.handleError('下载失败,请稍后重试!');
            console.error('个人参赛名单下载失败 ->', error);
          },
        );
    
      }


  
      
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param ResultData - optional value to return as the observable ResultData
   */
  private handleError<T>(operation = 'operation', ResultData?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for module consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty ResultData.
      return of(ResultData as T);
    };
  }

  /** Log a ModuleService message with the MessageService */
  private log(message: string) {
    // this.messageService.info('ModuleService: ' + message);
    console.log('ResultDataService: ' + message);
  }


  statisBimInfoHomeData() {
    const url = `${this.resourceUrl}/statisBimInfoHomeData`;
    return this.http.get(url, { observe: 'response' });
  }


  getBimCertPassData(queryParams ?: any) {
    const url = `${this.resourceUrl}/getBimCertPassData`;
    return this.http.get(url, { params: queryParams, observe: 'response' })
        .pipe(map((res: HttpResponse<any>) => this.convertDateFromServer(res)));
  }

  getBimCertPassDetailPage(queryParams ?: any) {
    const url = `${this.resourceUrl}/getBimCertPassDetailPage`;
    return this.http.get(url, { params: queryParams, observe: 'response' })
        .pipe(map((res: HttpResponse<any>) => this.convertDateFromServer(res)));
  }

}

