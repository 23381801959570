import { Component,Inject } from '@angular/core';
import { _HttpClient,ModalHelper } from '@delon/theme';
import { OnInit,ViewChild } from '@angular/core';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';

import { SimpleTableComponent, SimpleTableData, SimpleTableColumn} from '@delon/abc';

import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { BimTeamsService } from './bim-teams.service';
import { BimTeams } from './bim-teams.model';
import { APP_PERMISSIONS } from '@shared/app.permissions';

import {BimTeamsEditComponent} from './edit/edit.component';
import { UserService } from "../../../../admin/system/users/users.service";
import {ProvinceService} from '../../provinces/provinces.service';
import {UcommonService} from '../../ucommon//ucommon.server';
import {BimInfoEventsService} from '../bim-info-events/bim-info-events.service';

import {BimTeamTeacherListComponent} from './bim-team-teacher/bim-team-teacher-list.component';

import {BimTeamStudentListComponent} from './bim-team-student/bim-team-student-list.component';
import {BimTeamItemsService} from './bim-team-items/bim-team-items.service';
import { resolve } from 'url';
@Component({
  selector: 'app-bim-bimTeams',
  templateUrl: './bim-teams-list.component.html',
  styleUrls: ['./bim-teams-list.component.less']
})
export class BimTeamsListComponent implements OnInit {
  BimItems = {};
  public schoolTypeList = {'':'全部','0': '本科',  '1' : '专科', '2': '中职'};

  public schoolAuditeList = {'':'全部','0': '未通过',  '1' : '通过'};//,'false':'未审核'
  public schoolTopeList = {'':'全部','0': '未晋级',  '1' : '晋级'};
  public page  = 0;
  public size  = 10;
  public total ;
  public APP_PERMISSIONS = APP_PERMISSIONS;
  public list: BimTeams[] = [];
  public totalCallNo  = 0;
  public selectedRows: SimpleTableData[] = [];
  locationList=[];
  provinceList=[];
  bimEventsList=[];

  userLocationList = [];
  userProvinceList=[];
 
  entityId:string;
  entityName:String;
  accountName:String;
  isloadding = false;
  public bimInfoStatus = 1;//-1 全部;
  
  public currentBimInfo = null;
  curBimEventId:string;

  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;

  queryParams = {
    // 查询搜索框的字段要预置
    'keyword.contains': '',
    'schoolType.contains': '',
    'regionId.contains': '',
    'province.contains': '',
    'userDefine1.equals': '',
    'belongType.contains': '',
    'bimInfoEventsId.contains':'',
    'schoolTop.equals':'',

    // sort固定
     sort: ['createTime,desc']
  };


  columns: SimpleTableColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '团队名称', index: 'name' , width: '15%'},
    { title: '邮箱', index: 'email' , width: '15%'},

    { title: '学校', index: 'schoolName' , width: '5%'},
    { title: '学制', index: 'schoolType' , width: '5%',
      format: (item: any) => {
        if (item.schoolType === '1') {
          return '专科';
        } else if (item.schoolType === '0') {
          return '本科';
        } else if (item.schoolType === '2') {
          return '中职';
        } else{
          return item.schoolType;
        }
      }
    },

    { title: '参赛专项',  width: '10%',buttons: [
       { text: '点击查看',  click: (item:any) => this.viewTeamItems(item), },
    ]
  },
    { title: '报名时间', index: 'regDate' , width: '10%'},

    { title: '区域', index: 'regionId' , width: '5%',
      format: (item: any) => {
         return this.findLocationName(item.regionId);
      }
    },
    { title: '省份', index: 'province' , width: '5%',
      format: (item: any) => {
        return this.findProvinceName(item.province); 
      }
    },
   
    
    
    { title: '状态', index: 'userDefine1' , width: '5%',
      format: (item: any) => {
        if (item.userDefine1 === '1') {
          return '通过';
        } else if (item.userDefine1 === '0') {
          return '未通过';
        } else{
          return '未审核';
        }
      }
    },

    { title: '晋级', index: 'schoolTop' , width: '5%',
    format: (item: any) => {
      if (item.schoolTop === 1) {
        return '晋级';
      } else {
        return '未晋级';
      }
    }
  },
      // { title: '截图', index: 'imgurl' },
    // { title: '权重', index: 'per', width: '12%' },
    // { title: '分类', index: 'type', width: '12%' },
    // { title: '序号', index: 'orderSn', width: '12%' },
    {
      title: '操作', className: 'text-center', width: '20%',
      buttons: [
        // { text: '编辑', type: 'modal', component: BimSchoolsEditComponent, paramName: 'record', click: () => this.getDataList(), },

        { text: '队员',  click: (item:any) => this.editTeamStudents(item), },
        { text: '指导老师',  click: (item:any) => this.editTeamTeacher(item), },

        { text: '编辑',  click: (item:any) => this.edit(item), },
        { text: '删除',  click: (item: any) => { this.remove(item); } },
        { text: '审核通过',  click: (item:any) => this.audit(item,1), },
        { text: '取消审核',  click: (item:any) => this.audit(item,0), },
        { text: '晋级',  click: (item:any) => this.schoolTop(item, 1), },
        { text: '取消晋级',  click: (item:any) => this.schoolTop(item, 0), },


      ],
    }
  ];

  constructor(
    public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public modalService: NzModalService,
    public bimTeamsService: BimTeamsService,
    public userService:UserService,
    public provinceService:ProvinceService,
    public ucommonService:UcommonService,
    public bimInfoEventsService: BimInfoEventsService,
    private bimTeamItemsService:BimTeamItemsService,
    @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
  ) {

    this.entityId = tokenService.get().entity_id;
    this.entityName = tokenService.get().entity_name;
    this.accountName = tokenService.get().account_name;


  }

  ngOnInit() {
    if(this.accountName === 'admin')
    {
      this.loadLocationList();
      this.loadprovinceList();

      this.getDataList();
    }else{
      this.getUserProvinces();
    }
  }


  viewTeamItems(tems){

    let copyParams = {};
    copyParams['teamId.contains'] = tems.id;
    debugger;
    this.bimTeamItemsService.query(copyParams).subscribe((res: any) => {
       let record =  res.body;
       let itemNames = "";
       if(record){
           record.forEach(element => {
               if(itemNames === ""){
                itemNames = element.itemName;
               }else{
                itemNames = itemNames + ',' +  element.itemName;
               }
           });

           this.msg.info(itemNames);
       }else{
        this.msg.info('没有报名专项!');
       }
    });
  }


/**
 * 获取用户地区信息
 */
  getUserProvinces(){
     this.userProvinceList = [];
     this.userLocationList = [];
    this.provinceService.getUserProvinces()
    .subscribe((res: any) => {
      
        res.body.forEach(element => {
            this.userProvinceList.push(element.id);
            this.userLocationList.push(element.fkregionId);

            
        });
        this.loadLocationList();
        this.loadprovinceList();
        this.getDataList();

        //this.userProvinceList = res.body;
        console.log(res.body);
    });
  }


  /**
  * 获取数据列表
  * @param {string} url
  */
  public getDataList(isReset?: boolean) {
    this.isloadding=true;
     if(this.bimEventsList != null && this.bimEventsList.length >0)
     {
       
     }else{
      this.list = [];
      this.total = 0;
      this.isloadding=false;
       return false;
     }

     let copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      
      this.st.reset();
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });
      this.queryParams["bimInfoEventsId.contains"] = this.bimEventsList[0].id;
      copyParams["bimInfoEventsId.contains"] = this.bimEventsList[0].id;
    } else {
      Object.keys(q).forEach(function (key) {
        if(key ==='keyword.contains' && q[key] !== '' &&　q[key] !== null )
        {
          copyParams['name.contains'] = q[key];
           copyParams['schoolName.contains'] = q[key];
           copyParams['email.contains'] = q[key];
        }if(key ==='userDefine1.equals' && q[key] === 'false'){
          copyParams['userDefine1.specified'] = q[key];
        }else if (q[key] !== '' &&　q[key] !== null) {
          copyParams[key] = q[key];
        }
      });
    }

    copyParams['page'] = this.page;
    copyParams['size'] = this.size;


    this.curBimEventId =  copyParams["bimInfoEventsId.contains"];

    let tmplocation = copyParams['regionId.contains'];

    if(this.accountName !=='admin' &&(( tmplocation ==null || tmplocation==undefined ||tmplocation.length <= 0) || isReset === true))
    {   
      //delete copyParams['location.contains'];
      // copyParams['regionId.in'] = this.userLocationList;
    }

    let tmpprovince = copyParams['province.contains'];

    if(this.accountName !=='admin' && ((tmpprovince ==null || tmpprovince==undefined || tmpprovince.length <= 0)|| isReset === true))
    {
      //delete copyParams['province.contains'];
      copyParams['province.in'] = this.userProvinceList;
    }

    copyParams['isDelete.equals'] = 0;
    this.bimTeamsService.queryNew(copyParams)
      .subscribe((res: any) => {

      this.list = res.body;
      this.total = res.headers.get('X-Total-Count');
      this.isloadding=false;
      });
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event) {
    console.log(event);
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
  * 过滤器变动事件 支持多选过滤
  * @param
  */
  filterChange(event) {
    let i = 0;
    const _value = [];
    const _type = event.filterMultiple ? 'in' : 'equals';
    event.filters.forEach(element => {
      if (element.checked) {
        _value[i++] = element.value;
      }
    });
    this.queryParams[event.indexKey + '.' + _type] = _value;
    this.getDataList();
  }

  /**
  * 排序变动事件
  * @param
  */
  sortChange(event) {
    const array = this.queryParams['sort'];
    const length = array.length;
    let isInArray = false;
    let value = null;
    if (event.value === 'descend') {
      value = 'desc';
    } else if (event.value === 'ascend') {
      value = 'asc';
    }
    for (let i = 0; i < length; i++) {
      if (array[i].startsWith(event.column.indexKey)) {
        if (value == null) {
          array.splice(i, 1);
          isInArray = true;
          break;
        } else {
          array[i] = event.column.indexKey + ',' + value;
          isInArray = true;
          break;
        }
      }
    }
    if (value != null && !isInArray) {
      array.push(event.column.indexKey + ',' + value);
    }
    // 排序改变时，simpleTable会重置页码
    this.page = 0;
    this.getDataList();
  }


  /**
  * 新增页面
  */
  add() {

    
    if(this.curBimEventId === null || this.curBimEventId ===undefined || this.curBimEventId ==="-1")
    {
       this.msg.info('当前赛事没有团队赛项，不能添加参赛团队数据!');
       return false;
    }

    this.modal
      .static(BimTeamsEditComponent, { record: { id: null,bimInfoEventsId:this.curBimEventId },isAdd: true, bimInfoEventsId: this.curBimEventId })
      //      .pipe(filter(w => w === true))
      .subscribe(() => {
        this.getDataList();
      });
  }

  edit(record){
    this.modal
    .static(BimTeamsEditComponent, { record: record,isAdd: false, bimInfoEventsId: this.curBimEventId })

    .subscribe((res) => {

        this.getDataList();

      
    });
  }

  /**
   * 团队成员管理
   * @param record 
   */
  editTeamStudents(record){
    this.modal
    .static(BimTeamStudentListComponent, { teamId: record.id, schoolName:record.schoolName, teamName:record.name, bimEventId: this.curBimEventId})

    .subscribe((res) => {
      if(res === true)
      {
        this.getDataList();
      }
      
    });
  }
  /**
   * 团队老师管理
   * @param record 
   */
  editTeamTeacher(record){
    this.modal
    .static(BimTeamTeacherListComponent, {  teamId: record.id, schoolName:record.schoolName, teamName:record.name})

    .subscribe((res) => {
      if(res === true)
      {
        this.getDataList();
      }
      
    });
  }


/**
 * 审核或取消审核
 * @param record 
 * @param flag 
 */
  audit(record, flag){
     record.userDefine1=flag;
     this.bimTeamsService.update(record).subscribe(res => {
        if (record  ) {
          if(flag === 1)
          {
            this.msg.info('审核成功！');
          }else{
            this.msg.info('取消审核成功!');
          }
          
          this.getDataList();
        } else {
          if(flag === 1)
          {
            this.msg.info('审核失败！');
          }else{
            this.msg.info('取消审核失败!');
          }
        }
      });
    
  }

  /**
   * 晋级或取消晋级
   * @param record 
   * @param flag 
   */
  schoolTop(record, flag){

    this.bimTeamsService.saveTeamTop(record.id,flag).subscribe(res => {
      if (res.body && res.body['success']) {
        this.msg.success(flag === 1 ? '晋级成功！' : '取消晋级成功!');
         
        this.getDataList();
      } else {
        this.msg.info(flag === 1 ? '晋级失败！' : '取消晋级失败!');
      }
    })

    // record.schoolTop=flag;
    // this.bimTeamsService.update(record).subscribe(res => {
    //    if (record  ) {
    //      if(flag === 1)
    //      {
    //        this.msg.info('晋级成功！');
    //      }else{
    //        this.msg.info('取消晋级成功!');
    //      }
         
    //      this.getDataList();
    //    } else {
    //      if(flag === 1)
    //      {
    //        this.msg.info('晋级失败！');
    //      }else{
    //        this.msg.info('取消晋级失败!');
    //      }
    //    }
    //  });
 }



  /**
  * 删除
  */
  remove(res: any) {
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        res.isDelete = 1;
        this.bimTeamsService.update(res).subscribe(() => {
          this.getDataList();
          this.st.clearCheck();
        });
        // this.bimTeamsService.delete(res.id)
        //   .subscribe(() => {
        //     this.getDataList();
        //     this.st.clearCheck();
        //   });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  /**
  * 批量删除
  */
  removeBatch() {
    if (this.selectedRows.length < 1) return this.msg.warning('请先选择您要删除的信息！');
    let flag = false;
    this.selectedRows.forEach((ele) => {
      if (ele.userName === 'admin') {
        this.msg.info('管理员账号不能删除');
        flag = true;
      }
    });
    if (flag) {
      return ;
    }
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.bimTeamsService.delete(this.selectedRows.map(i => i.id))
          .subscribe(() => {
            this.getDataList();
            this.st.clearCheck();
          });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
    this.totalCallNo = this.selectedRows.reduce(
      (total, cv) => total + cv.callNo,
      0,
    );
  }



    /**
   * 加载区域列表
   */
  loadLocationList(){
    this.locationList = [];
    // 加载区域与省份数据
    const dataParams = {
      'id.in':this.userLocationList,
      'size' : '10000',
      'sort': 'orderNum,asc'
    };

      this.userService.queryRegionList(dataParams).subscribe(res => {
        
        if (res && res.body) {
          this.locationList = res.body;

          console.log(this.locationList);
        }
      });
  }


  
  /**
   * 加载省份列表
   */
  loadprovinceList(){
    this.provinceList = [];
    
    this.userService.queryProvinceList({
      'id.in':this.userProvinceList,
      'size' : '10000',
      'sort': 'orderNum,asc'
    }).subscribe(res => {
      if (res && res.body) {
        const data = res.body;
         this.provinceList = data;
      }
    });
  }

/**
 * 查询区域名
 * @param location 
 */
  findLocationName(location){
     let tmpList = this.locationList.filter(d=>d.id===location);
     if(tmpList.length >0)
     {
        return tmpList[0].regionName;
     }else{
       return '';
     }
  }

  /**
   * 查询省份名称
   * @param location 
   */

  findProvinceName(location){
    let tmpList = this.provinceList.filter(d=>d.id===location);
    if(tmpList.length >0)
    {
       return tmpList[0].provinceName;
    }else{
      return '';
    }
 }

 /**
  * 导出excel
  */

 exportExcel(){

      if(this.bimEventsList != null && this.bimEventsList.length >0)
      {
        
      }else{
        return false;
      }

      let copyParams = {};
      const q = this.queryParams;

      Object.keys(q).forEach(function (key) {
        if(key ==='keyword.contains' && q[key] !== '' &&　q[key] !== null )
        {
          copyParams['name.contains'] = q[key];
          copyParams['schoolName.contains'] = q[key];
          copyParams['email.contains'] = q[key];
        }else if (q[key] !== '' &&　q[key] !== null) {
          copyParams[key] = q[key];
        }
      });


      this.curBimEventId =  copyParams["bimInfoEventsId.contains"];

      let tmplocation = copyParams['regionId.contains'];

      if(this.accountName !=='admin' &&(( tmplocation ==null || tmplocation==undefined ||tmplocation.length <= 0)))
      {   
        // copyParams['regionId.in'] = this.userLocationList;
      }

      let tmpprovince = copyParams['province.contains'];

      if(this.accountName !=='admin' && ((tmpprovince ==null || tmpprovince==undefined || tmpprovince.length <= 0)))
      {
        copyParams['province.in'] = this.userProvinceList;
      }
      copyParams['isDelete.equals'] = 0;

      this.bimTeamsService.exportExcel(copyParams);
 }


/**
 * 选择赛事事件
 * @param bimInfo 
 */
  clickContest(bimInfo) {
      this.currentBimInfo = bimInfo;
      //this.queryParams["bimInfoId"] = this.currentBimInfo.id;
      this.getEventsDataList();
  }

/**
 * 获取赛事赛项
 * @param isReset 
 */
  public getEventsDataList(isReset?: boolean) {
      let copyParams = {sort: ['createTime,desc']};
      const q = this.queryParams;
    
      copyParams['page'] = 0;
      copyParams['size'] = 1000;
      copyParams['bimInfoId.contains'] = this.currentBimInfo.id;
      copyParams['eventsType.equals'] ='0';
      copyParams['isDelete.equals'] = 0;
      

      this.bimInfoEventsService.query(copyParams)
        .subscribe((res: any) => {
            if(res.ok && res.body.length >0)
            {
              this.bimEventsList = res.body;
              this.curBimEventId = this.bimEventsList[0].id;
              this.queryParams["bimInfoEventsId.contains"] = this.curBimEventId;
              this.getDataList();
            }else{
              this.bimEventsList = [];
              this.curBimEventId = "-1";
              this.queryParams["bimInfoEventsId.contains"] = "-1";
              this.getDataList();
            }
          });   
   }

/**
 * 赛项选择框改变
 * @param $event 
 */
   bimEventsChange($event){
     this.curBimEventId = $event;
     this.queryParams["bimInfoEventsId.contains"] = this.curBimEventId;
     this.getDataList();
   }

   /**
    * 其他选项改变
    * @param $event 
    */
   schoolTypeChange($event){
    this.page = 0;
    this.getDataList();
  }


  searche(){
    this.page = 0;
    this.getDataList();
  }

  /**
   * 导出报名汇总表excel
   */
 
  exportEnrollExcel(){
       if(this.bimEventsList != null && this.bimEventsList.length > 0) {
         
       } else {
         return false;
       }
 
       let copyParams = {};
       const q = this.queryParams;
 
       Object.keys(q).forEach(function (key) {
         if(key ==='keyword.contains' && q[key] !== '' && q[key] !== null ) {
           copyParams['name.contains'] = q[key];
           copyParams['schoolName.contains'] = q[key];
           copyParams['email.contains'] = q[key];
         } else if (q[key] !== '' && q[key] !== null) {
           copyParams[key] = q[key];
         }
       });
       this.curBimEventId =  copyParams["bimInfoEventsId.contains"];
       let tmplocation = copyParams['regionId.contains'];
       if(this.accountName !=='admin' && ((tmplocation ==null || tmplocation == undefined || tmplocation.length <= 0))) {   
         // copyParams['regionId.in'] = this.userLocationList;
       }
       let tmpprovince = copyParams['province.contains'];
       if(this.accountName !=='admin' && ((tmpprovince ==null || tmpprovince==undefined || tmpprovince.length <= 0))) {
         copyParams['province.in'] = this.userProvinceList;
       }
       copyParams['isDelete.equals'] = 0;
 
       this.bimTeamsService.exportEnrollExcel(copyParams);
  }
}
