import { Component, OnInit } from '@angular/core';
import { NzMessageService, NzModalRef } from 'ng-zorro-antd';
import { _HttpClient,ModalHelper } from '@delon/theme';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { BimPersonService } from '../../bim-person.service';
import { isNullOrUndefined } from 'util';
import * as moment from 'moment';
import { BimPersonResultService } from '../bim-person-result.service';

@Component({
  selector: 'app-person-result-edit',
  templateUrl: './edit-result.component.html',
  styleUrls: []
})
export class PersonResultEditComponent implements OnInit {

  record:any;

   // 是否增加
  isAdd: boolean;
 
  form: FormGroup;

  monthFormat = 'yyyy年MM月';

  isLoading = false;

  constructor(
    private fb: FormBuilder,
    public msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private bimPersonService: BimPersonService,
    private bimPersonResultService: BimPersonResultService,
    public modal: ModalHelper,
  ) {
    this.form = this.fb.group({
      id: [null, []],
      personId:[null, []],
      schoolName:[null, []],
      name:[null, []],
      itemId:[null, []],
      itemName:[null, []],
      scoreType:[null, []],

      score:[null, [Validators.required]],
      scoreRemark: [null, []],
      sortNo: [null, []],
      prizeType: [null, []],
      prizeLevel: [null, []],
      usedHms: [null, []],
      usedHmsParse: [null, []],
      examDate: [null, []],
      certDate: [null, []],
      certDateParse: [null, []],
    });
  }

  ngOnInit() {
    if (!this.isAdd) {
      this.loadData();
    }
  }


  /**
   * 加载页面data
   */
  loadData(): void {
    if (!isNullOrUndefined(this.record.certDate)) {
      const certDate = this.cnToArabic(this.record.certDate);
      if (certDate != null) {
        this.record.certDateParse = certDate.replace('年', '/').replace('月', '');
      }
    }
    if (!isNullOrUndefined(this.record.usedHms)) {
      let used = 0;
      const usedTimes = this.record.usedHms.split(':');
      if (!isNullOrUndefined(usedTimes[0])) {
        used = used + usedTimes[0] * 60 * 60;
      }
      if (!isNullOrUndefined(usedTimes[1])) {
        used = used + usedTimes[1] * 60;
      }
      if (!isNullOrUndefined(usedTimes[2])) {
        used = used + Number(usedTimes[2].split('.')[0]);
      }
      this.record.usedHmsParse = (used / 60).toFixed(2);
    }
    this.record['name'] = this.record.personName;
    console.log(this.record);
    this.form.patchValue(this.record);
  }


  // 保存
  submit() {
    const param = {};
    // tslint:disable-next-line: forin
    for (const key in this.form.controls) {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
      param[key] = this.form.controls[key].value;
    }

    if (this.form.invalid) {
      this.msg.error('请检查个人信息是否完整');
      return;
    }
    if (!isNullOrUndefined(param['certDateParse'])) {
      const certDate = moment(param['certDateParse']).format('YYYY年M月');
      param['certDate'] = certDate;
    }
    if (!isNullOrUndefined(param['usedHmsParse'])) {
      const second = param['usedHmsParse'] * 60;
      param['usedHms'] = this.secToHour(second);
    }
    console.log(param);
    this.isLoading = true;
    if (isNullOrUndefined(param['id'])) {
      param['scoreType'] = '专项成绩';
      this.bimPersonResultService.create(param).subscribe(res => {
        this.isLoading = false;
        this.nzModalRef.destroy();
      }); 
    } else {
      this.bimPersonResultService.find(param['id']).subscribe(res => {
        if (res && res.body) {
          const data = res.body;
          for (const key in param) {
            data[key] = param[key];
          }
          console.log(data);
          this.bimPersonResultService.update(data).subscribe(dRes => {
            this.isLoading = false;
            this.nzModalRef.destroy();
          });
        };
      });
    }
  }

  Cancel(type?){
     this.nzModalRef.destroy(type);
  }

  convertDataToChinese(dateStr) {
    var dict = {
        '0': '零',
        '1': '一',
        '2': '二',
        '3': '三',
        '4': '四',
        '5': '五',
        '6': '六',
        '7': '七',
        '8': '八',
        '9': '九',
        '10': '十',
        '11':'十一',
        '12':'十二',
        '13':'十三',
        '14':'十四',
        '15':'十五',
        '16':'十六',
        '17':'十七',
        '18':'十八',
        '19':'十九',
        '20':'二十',
        '21':'二十一',
        '22':'二十二',
        '23':'二十三',
        '24':'二十四',
        '25':'二十五',
        '26':'二十六',
        '27':'二十七',
        '28':'二十八',
        '29':'二十九',
        '30':'三十',
        '31':'三十一'
    };
    var date = dateStr.split('-'),
        yy = date[0],
        mm = date[1],
        dd = date[2];

    var yearStr = dict[yy[0]] + dict[yy[1]] + dict[yy[2]] + dict[yy[3]] + '年',
        monthStr = dict[''+Number(mm)] + '月',
        dayStr = dict[dd]; 
        console.log(yearStr +monthStr +dayStr);
  }

  cnToArabic(chineseStr) {
    const numMap = {
        '零': 0, '一': 1, '二': 2,
        '三': 3, '四': 4, '五': 5,
        '六': 6, '七': 7, '八': 8,
        '九': 9
    };
    const reg = /[零一二三四五六七八九]/g;
    return chineseStr.replace(reg, (match) => numMap[match]);
  }

  arabicToCn(chineseStr) {
    const numMap = {
        0: '零', 1: '一', 2: '二',
        3: '三', 4: '四', 5: '五',
        6: '六', 7: '七', 8: '八',
        9: '九'
    };
    const reg = /[0123456789]/g;
    return chineseStr.replace(reg, (match) => numMap[match]);
  }

  secToHour(time) {
    let timeStr = '';
    let hour;
    let minute;
    let second;
    if (time <= 0)
        return '00:00:00';
    else {
        minute = time / 60;
        if (minute < 60) {
            second = time % 60;
            timeStr = timeStr + ('00:' + this.unitFormat(minute) + ':' + this.unitFormat(second));
        } else {
            hour = Number(minute / 60).toString().split('.')[0];
            minute = Number(minute % 60).toString().split('.')[0];
            second = Number(time - hour * 3600 - minute * 60).toString().split('.')[0];
            timeStr = timeStr + (this.unitFormat(hour) + ':' + this.unitFormat(minute) + ':' + this.unitFormat(second));
        }
    }
    return timeStr.toString();
  }

  unitFormat(i) {
    let retStr = '';
    if (i >= 0 && i < 10)
        retStr = retStr + '0' + i;
    else
        retStr = retStr + i;
    return retStr.toString();
  }
}
